import {CellularSharp, FingerPrintSharp, MailUnreadSharp} from "react-ionicons";
import React, {useState} from "react";
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import QRCode from "qrcode";
import {hsoIdApi, useRegisterMfaMutation, useSendMfaMutation} from "../../services/hsoid";
import {useAppDispatch} from "../../hooks";


const mapState = (state: RootState) => ({
  authentication: state.authentication
});

type PropsFromRedux = ConnectedProps<typeof connector>

interface MyProps {
    initial: boolean;
    closeFormFunc?: Function;
}


type Props = PropsFromRedux & MyProps;
const MfaSetup = (props: Props) => {

    const [mfamethod, setMethod] = useState<string>('');
    const [target, setTarget] = useState<string>('');
    const [validationError, setValidationError] = useState<string>('');
    const [doRegisterMfa, {data: registerData, isLoading: isRegistering, isError: isRegisterError, isSuccess: isRegisteredSuccess}] = useRegisterMfaMutation();
    const [qrCodeUrl, setQrCodeUrl] = useState<string>('');
    const [doMfa, { data: mfaData, isLoading: isMfaLoading, isError, error: mfaError, isSuccess: isMfaSuccess }] =  useSendMfaMutation();
    const dispatch = useAppDispatch();
    const [mfaCodeError, setMfaCodeError] = useState<boolean>(false);
    const [mfacode, setMfaCode] = useState<string>('');


    const handleCancel = () => {
        if(props.closeFormFunc) {
            props.closeFormFunc(false);
        }
    }

    const submitMfa = (e: React.FormEvent) => {
        e.preventDefault();
        doMfa({code: mfacode}).then((c) => {
            if('error' in c) {
                setMfaCodeError(true);
            }
            else {
                dispatch(hsoIdApi.endpoints.mfaMethods.initiate());
                dispatch(hsoIdApi.endpoints.authStatus.initiate());
                if(props.initial === false && props.closeFormFunc) {
                    props.closeFormFunc(false);
                }
            }
        });
    }

    const submitNewMfa = (e: React.FormEvent) => {
        e.preventDefault();
        setValidationError('');
        let validated = true;
        switch (mfamethod) {
            case 'SMS':
                if(!target.match(/^[1-9]{2,3}[0-9]{5,}$/g)) {
                    validated = false;
                    setValidationError('Invalid phone number');
                }
                if(target.match('^44') && target.length !== 12) {
                    validated = false;
                    setValidationError('UK Phone number has the wrong number of digits');
                }
                break;
            case 'EMAIL':
                if(!target.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
                    validated = false;
                    setValidationError('Invalid email address');
                }
                break;
            case 'TOTP':
                if(target.length < 2) {
                    validated = false;
                    setValidationError('You must fill in this field');
                }
        }
        if(validated) {
            doRegisterMfa({method: mfamethod, target}).then((data) => {
                if('data' in data) {
                    if(data.data.url) {
                        QRCode.toDataURL(data.data.url).then(setQrCodeUrl);
                    }
                }
            })
        }
    }

    const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setTarget('');
        setValidationError('');
        const value = event.target.value;
        setMethod(value);
    };

    return(
        <div className="container">
        <div className="content">
            <div className="columns mt-6">
                <div className="column is-mobile is-three-quarters-mobile is-offset-2-mobile is-half is-offset-one-quarter">
                    <div className="panel is-primary">
                        <div className="panel-heading">Add MFA Method</div>
                            {props.initial &&
                                <div className="panel-block has-background-white">
                                    <div>
                                    <p>hSo now require all accounts to be authenticated using Multi-Factor Authentication and you do not have any methods enabled on your account.</p>
                                    <p>Please complete the form below to add your initial MFA method. Additional methods may be added once logged in.</p>
                                    </div>
                                </div>
                            }
                        <div className={"panel-block has-background-white" + (isRegisteredSuccess ? " is-hidden" : "")}>
                            <form style={{width: '100%'}} onSubmit={submitNewMfa}>
                                <div className="field">
                                    <label className="label">Method</label>
                                    <div className="select is-rounded">
                                        <select onChange={selectChange}>
                                            <option value="">-- Select</option>
                                            <option value="TOTP">Software Token</option>
                                            <option value="EMAIL">Email</option>
                                            <option value="SMS">SMS</option>
                                        </select>
                                    </div>
                                </div>
                                {mfamethod === 'TOTP' &&
                                    <>
                                        <article className="message is-small">
                                            <div className="message-body">
                                                Use an app on your mobile device to generate one time codes.
                                            </div>
                                        </article>
                                         <div className="field">
                                            <label className="label">Name</label>
                                            <div className="control has-icons-left has-icons-right">
                                                <input className={"input is-medium" + (validationError === "" ? "" : " is-danger")} type="text" placeholder="e.g. iPhone"
                                                       onChange={e => setTarget(e.target.value)} value={target} />
                                                <span className="icon is-small is-left">
                                                  <FingerPrintSharp color={"#c0c0c0"}/>
                                                </span>
                                            </div>
                                            <p className="help">A name to remind you which device you have added the token to.</p>
                                            {validationError !== "" && <p className="help is-danger">{validationError}</p>}
                                        </div>
                                    </>
                                }
                                {mfamethod === 'EMAIL' &&
                                    <>
                                        <article className="message is-small">
                                            <div className="message-body">
                                                A random code will be sent to the registered email account on each login.
                                            </div>
                                        </article>
                                        <div className="field">
                                            <label className="label">Email Address</label>
                                            <div className="control has-icons-left has-icons-right">
                                                <input className={"input is-medium" + (validationError === "" ? "" : " is-danger")} type="text" placeholder="Email Address"
                                                       onChange={e => setTarget(e.target.value)} value={target} />
                                                <span className="icon is-small is-left">
                                                  <MailUnreadSharp color={"#c0c0c0"}/>
                                                </span>
                                            </div>
                                            {validationError !== "" && <p className="help is-danger">{validationError}</p>}
                                        </div>
                                    </>
                                }
                                {mfamethod === 'SMS' &&
                                    <>
                                        <article className="message is-small">
                                            <div className="message-body">
                                                A random code will be sent to the registered phone number via SMS on each login.
                                            </div>
                                        </article>
                                        <div className="field">
                                            <label className="label">Mobile Number</label>
                                            <div className="control has-icons-left has-icons-right">
                                                <input className={"input is-medium" + (validationError === "" ? "" : " is-danger")} type="text" placeholder="e.g. 447725000000"
                                                       onChange={e => setTarget(e.target.value)} value={target} />
                                                <span className="icon is-small is-left">
                                                  <CellularSharp color={"#c0c0c0"}/>
                                                </span>
                                            </div>
                                            <p className="help">Numbers only, starting with the country code.</p>
                                            {validationError !== "" && <p className="help is-danger">{validationError}</p>}
                                        </div>
                                    </>
                                }

                                {mfamethod !== '' &&
                                <>
                                <div className="has-text-right">
                                    <button className="button is-primary" disabled={isMfaLoading}>Next Step</button>
                                </div>
                                </>
                                }
                            </form>

                        </div>
                        <div className={"panel-block has-background-white" + (isRegisteredSuccess ? "" : " is-hidden")}>
                            <div style={{width: "100%"}}>
                                {mfamethod === 'SMS' &&
                                    <article className="message is-small">
                                        <div className="message-body">
                                            A code has been sent to the number entered. Please enter it in the box below and verify to complete the process.
                                        </div>
                                    </article>
                                }
                                {mfamethod === 'EMAIL' &&
                                    <article className="message is-small">
                                        <div className="message-body">
                                            A code has been sent to the email address entered. Please enter it in the box below and verify to complete the process.
                                        </div>
                                    </article>
                                }
                            {mfamethod === 'TOTP' &&
                                <>
                                <div className="has-text-centered">
                                    <img
                                      className="block w-64 h-64 object-contain"
                                      src={qrCodeUrl}
                                      alt="qrcode url"
                                    />
                                    {registerData?.seed}
                                </div>
                                    <article className="message is-small">
                                        <div className="message-body">
                                            <ul>
                                      <li>
                                        Install Google Authenticator (IOS - Android) or Authy (IOS -
                                        Android).
                                      </li>
                                      <li>In the authenticator app, select "+" icon.</li>
                                      <li>
                                        Select "Scan a barcode (or QR code)" and use the phone's camera
                                        to scan this barcode.
                                      </li>
                                        <li>
                                            Once added to the device, enter the current code below to verify and enable.
                                        </li>
                                    </ul>
                                        </div>
                                    </article>
                            </>
                            }
                            <form onSubmit={submitMfa}>
                                <div className="field">
                                    <label className="label">One Time Code</label>
                                    <div className="control has-icons-left has-icons-right">
                                        <input className={"input is-medium" + (mfaCodeError && " is-danger")} type="text" placeholder="Code"
                                               onChange={e => setMfaCode(e.target.value)} value={mfacode} />
                                        <span className="icon is-small is-left">
                                          <FingerPrintSharp color={"#c0c0c0"}/>
                                        </span>
                                    </div>
                                    {mfaCodeError && <p className="help is-danger">Incorrect MFA Code</p>}
                                </div>
                                <div className="has-text-right">
                                    <button className="button is-primary" disabled={isMfaLoading}>Verify & Enable</button>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                    {props.closeFormFunc &&
                        <p className={"has-text-centered"}><a onClick={handleCancel}>Cancel</a></p>
                    }
                </div>
            </div>
        </div>
    </div>
    )
}

const connector = connect(mapState);

export default connector(MfaSetup);