import {Link, NavLink, useSearchParams} from "react-router-dom";
import {hsoIdApi} from "../services/hsoid";
import {clearAuthData} from "../features/authentication/reducer";
import React from "react";
import {ReactComponent as HsoLogoWhite} from "../../hSo_logo_white.svg";
import {useAppDispatch} from "../hooks";
import {useCookies} from "react-cookie";


export const HeaderBar = () => {

    const dispatch = useAppDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(['hso-identity-session']);

    return(
        <nav className="navbar is-primary">
                <div className="container">
                <div className="navbar-brand">
                    <a className="navbar-item" href="/">
                        <HsoLogoWhite style={{height: '30px'}}/>
                    </a>

                    <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false"
                       data-target="navbarBasicExample">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>
                <div id="navbarBasicExample" className="navbar-menu">
                    <div className="navbar-start">
                        <NavLink to={"/"} className={({ isActive }) =>
                            isActive ? 'navbar-item is-active' : 'navbar-item'
                          }>
                            Home
                        </NavLink>
                        <NavLink to={"/mfa"}  className={({ isActive }) =>
                            isActive ? 'navbar-item is-active' : 'navbar-item'
                          }>
                            MFA
                        </NavLink>
                        <NavLink to="/clients" className={({ isActive }) =>
                            isActive ? 'navbar-item is-active' : 'navbar-item'
                          }>
                            API Clients
                        </NavLink>
                    </div>
                </div>
                <div className="navbar-end">
                    <a className="navbar-item" href="https://portal.hso.co.uk">
                        Portal
                    </a>

                    <a className="navbar-item" onClick={() => {
                        removeCookie('hso-identity-session');
                        dispatch(hsoIdApi.util.resetApiState());
                        dispatch(clearAuthData());
                    }}>
                        Logout
                    </a>

                </div>
                    </div>
            </nav>
    )
}