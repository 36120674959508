import {FingerPrintSharp, GlobeSharp, ShapesSharp, StarSharp, TrashSharp} from "react-ionicons";
import React, {useState} from "react";
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import {useMfaMethodsQuery, useDeleteMfaMethodMutation, useSetMfaPreferredMutation} from "../../services/hsoid";
import MfaSetup from "./mfasetup";

const mapState = (state: RootState) => ({
  authentication: state
});

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux;

const MfaComponent = (props: Props) => {
    const {data, error, isLoading} = useMfaMethodsQuery();
    const [deleteMfaMethod] = useDeleteMfaMethodMutation();
    const [setMfaPreferred] = useSetMfaPreferredMutation();
    const [showSetup, setShowSetup] = useState<boolean>(false);

    const handleAddButtonClick = () => {
        setShowSetup(true);
    }

    const handleDelete = (id: string) => {
        deleteMfaMethod(id);
    }

    const handleSetPreferred = (id: string) => {
        setMfaPreferred(id);
    }

    if(!data) {
        return(<p>Loading</p>)
    }

    if(showSetup) {
        return(<MfaSetup initial={false} closeFormFunc={setShowSetup}/>)
    }

    return(
        <div className="container">
                <div className="content">
                    <div className="columns mt-6">
                        <div className="column">
                            <h1 className="title">MFA Methods</h1>
                            <div className={"box"}>
                                <button className="button is-primary is-pulled-right is-small" onClick={handleAddButtonClick}>Add New</button>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>Preferred</th>
                                        <th>Method</th>
                                        <th>Target</th>
                                        <th>Active</th>
                                        <th>Last Used</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.map((m) => {
                                        return(
                                            <tr>
                                                <td>{m.preferred === true && <span className="tag">Yes</span>}</td>
                                                <td>{m.method}</td>
                                                <td>{m.method === 'SMS' && "+"}{m.target}</td>
                                                <td>{m.active === true ? <span className="tag is-success">Yes</span> : <span className="tag is-danger">No</span>}</td>
                                                <td>{m.last_used}</td>
                                                <td>
                                                    <button className="button is-small is-primary is-inverted" disabled={m.preferred} onClick={() => handleSetPreferred(m.id) }>
                                                        <StarSharp/>
                                                    </button>
                                                    <button className="button is-small is-danger is-inverted" disabled={m.in_use || m.preferred} onClick={() => handleDelete(m.id) }>
                                                        <TrashSharp/>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const connector = connect(mapState);

export default connector(MfaComponent);