import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  LoginRequest,
  AuthStatus,
  MfaMethod,
  MfaRequest,
  MfaRegisterRequest,
  MfaRegistrationResponse,
  MfaVerifyRequest,
  MfaSetRequest,
  ApiClient,
  ApiClientRegisterRequest,
  ApiClientSecretAddRequest,
  ApiClientSecretPrivate, ApiClientSecretDeleteRequest, ApiClientScopeAddRequest, ApiClientScopeDeleteRequest
} from "../types";
import {AuthenticationState} from "../features/authentication/reducer"

export const hsoIdApi = createApi({
  reducerPath: 'hsoid',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    credentials: "include",
  }),
  tagTypes: ['Auth', 'MfaMethods', 'ApiClients'],
  endpoints: (builder) => ({
    authStatus: builder.query<AuthStatus, void>({
      query: () => 'auth/status',
      providesTags: ['Auth']
    }),
    mfaMethods: builder.query<MfaMethod[], void>({
      query: () => 'auth/mfamethods',
      providesTags: ['MfaMethods']
    }),
    clientIds: builder.query<ApiClient[], void>({
      query: ()=> 'clients/',
      providesTags: ['ApiClients']
    }),
    mfaResend: builder.query<void, void>({
      query: () => 'auth/resendmfa',
    }),
    login: builder.mutation<AuthenticationState, LoginRequest>({
      query: ({username, password}) => ({
        url: 'auth/login',
        method: 'POST',
        body: {
          username,
          password
        }
      }),
      invalidatesTags: ['Auth']
    }),
    sendMfa: builder.mutation<AuthenticationState, MfaRequest>({
      query: ({code}) => ({
        url: 'auth/verifymfa',
        method: 'POST',
        body: {
          code
        }
      }),
      invalidatesTags: ['MfaMethods', 'Auth']
    }),
    setMfaMethod: builder.mutation<void, MfaSetRequest>({
      query: ({method}) => ({
        url: 'auth/setmfamethod',
        method: 'POST',
        body: {
          method
        }
      }),
      invalidatesTags: ['MfaMethods', 'Auth']
    }),
    registerMfa: builder.mutation<MfaRegistrationResponse, MfaRegisterRequest>({
      query: ({method, target}) => ({
        url: 'auth/mfamethods',
        method: 'POST',
        body: {
          method,
          target
        }
      }),
      invalidatesTags: ['MfaMethods']
    }),
    deleteMfaMethod: builder.mutation<string, string>({
      query: (uuid) => ({
        url: 'auth/mfamethods/' + uuid,
        method: 'DELETE'
      }),
      invalidatesTags: ['MfaMethods']
    }),
    setMfaPreferred: builder.mutation<string, string>({
      query: (uuid) => ({
        url: 'auth/mfamethods/' + uuid,
        method: 'PUT'
      }),
      invalidatesTags: ['MfaMethods']
    }),
    addNewClient: builder.mutation<ApiClient, ApiClientRegisterRequest>({
      query: ({client_name, require_auth_time}) => ({
        url: 'clients/',
        method: 'POST',
        body: {
          client_name,
          require_auth_time
        }
      }),
      invalidatesTags: ['ApiClients']
    }),
    addClientSecret: builder.mutation<ApiClientSecretPrivate, ApiClientSecretAddRequest>({
      query: ({name, expires, client_id}) => ({
        url: 'clients/'+client_id+'/secret',
        method: 'POST',
        body: {
          name,
          expires
        }
      }),
      invalidatesTags: ['ApiClients']
    }),
    delClientSecret: builder.mutation<string, ApiClientSecretDeleteRequest>({
      query: ({client_id, secret_id}) => ({
        url: 'clients/'+client_id+'/secret/'+secret_id,
        method: 'DELETE'
      }),
      invalidatesTags: ['ApiClients']
    }),
    addScope: builder.mutation<string, ApiClientScopeAddRequest>({
      query: ({client_id, scope}) => ({
        url: 'clients/'+client_id+'/scope',
        method: 'POST',
        body: scope
      }),
      invalidatesTags: ['ApiClients']
    }),
    deleteScope: builder.mutation<string, ApiClientScopeDeleteRequest>({
      query: ({client_id, scope_id}) => ({
        url: 'clients/'+client_id+'/scope/'+scope_id,
        method: 'DELETE'
      }),
      invalidatesTags: ['ApiClients']
    }),
  }),
})

export const {
  useLoginMutation,
  useAuthStatusQuery,
  useMfaMethodsQuery,
  useSendMfaMutation,
  useRegisterMfaMutation,
  useDeleteMfaMethodMutation,
  useSetMfaPreferredMutation,
  useLazyMfaResendQuery,
  useSetMfaMethodMutation,
  useClientIdsQuery,
  useAddNewClientMutation,
  useAddClientSecretMutation,
  useDelClientSecretMutation,
  useAddScopeMutation,
  useDeleteScopeMutation,
} = hsoIdApi;