import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import Authentication from './features/authentication/reducer';
import {hsoIdApi} from "./services/hsoid";
import { setupListeners } from '@reduxjs/toolkit/query'


export const store = configureStore({
  reducer: {
    authentication: Authentication,
    [hsoIdApi.reducerPath]: hsoIdApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(hsoIdApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

setupListeners(store.dispatch)