import React from "react";
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import {useClientIdsQuery} from "../../services/hsoid";
import {SearchSharp, StarSharp, TrashSharp} from "react-ionicons";
import {Link} from "react-router-dom";

const mapState = (state: RootState) => ({
  authentication: state
});

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux;

const ClientsComponent = (props: Props) => {
    const {data, error, isLoading} = useClientIdsQuery();

    if(!data) {
        return(<p>Loading</p>)
    }

    return(
        <div className="container">
                <div className="content">
                    <div className="columns mt-6">
                        <div className="column">
                            <h1 className="title">API Clients</h1>
                            <div className={"box"}>
                                <Link to={"/clients/add"} className="button is-primary is-pulled-right is-small" >Add New</Link>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Redirect URIs</th>
                                        <th>Secrets</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.map((m) => {
                                        return(
                                            <tr>
                                                <td>{m.client_id}</td>
                                                <td>{m.client_name}</td>
                                                <td>{m.redirect_uris.length}</td>
                                                <td>{m.secrets.length}</td>
                                                <td><Link to={"/clients/"+m.client_id}><SearchSharp/></Link></td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

const connector = connect(mapState);

export default connector(ClientsComponent);