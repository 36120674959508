import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import {Cookies} from 'react-cookie';
import {stat} from "fs";


interface MfaMethod {
    id: string;
    method: string;
    preferred: boolean;
}

export interface AuthenticationState {
    username: string | null;
    expires: number;
    session: string | null;
    cookie: string | null;
    mfa_methods: MfaMethod[];
}

const cookies = new Cookies();

// @ts-ignore
const initialState: AuthenticationState = {
    username: null,
    expires: -1,
    session: null,
    cookie: cookies.get('hso-identity-session'),
}

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        clearAuthData: state => {
            state.expires = -1;
            state.session = null;
            state.cookie = null;
            state.username = null;
        },
        setAuthData: (state, action: PayloadAction<AuthenticationState>) => {
            state.expires = action.payload.expires;
            state.session = action.payload.session;
            state.cookie = action.payload.cookie;
            state.username = action.payload.username;
        }
    }
});

export const { clearAuthData, setAuthData } = authenticationSlice.actions

export const selectExpires = (state: RootState) => state.authentication.expires;
export const selectSessionId = (state: RootState) => state.authentication.session;

export default authenticationSlice.reducer;


