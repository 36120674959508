import React from 'react';
import {RootState, store} from "./app/store";
import {
    BrowserRouter as Router,
    Routes,
    Route, useSearchParams,
} from "react-router-dom";
import './App.sass';
import LoginComponent from "./app/views/login";
import {useAuthStatusQuery} from "./app/services/hsoid";
import HomeComponent from "./app/views/home";
import MfaComponent from "./app/views/mfa";
import ClientsComponent from "./app/views/clients";
import MfaPromptComponent from "./app/views/login/mfaprompt";
import {connect, ConnectedProps} from "react-redux";
import {HeaderBar} from "./app/components/header";
import AddClient from "./app/views/clients/add";
import Client from "./app/views/clients/client";


const mapState = (state: RootState) => ({
  authentication: state
});

type PropsFromRedux = ConnectedProps<typeof connector>

function App(props: any) {
    const auth = store.getState().authentication.cookie;
    const {data, error, isLoading} = useAuthStatusQuery();

    if(error) {
        if ('status' in error) {
            const errMsg = 'error' in error ? error.error : JSON.stringify(error.data)
            if(error.status === 428) {
                // @ts-ignore
                return(<MfaPromptComponent method={error.data.detail.mfa_method}/>)
            }
            if(error.status === 403) {
                return (<LoginComponent/>);
            }
            return (<div className="notification is-danger">{errMsg}</div>)
        }
        return (<div className="notification is-danger">{error.message}</div>)
    }

    if (!data) {

    }

    if (isLoading) {
        return (
            <div className="container">
                <div className="content">
                    <div className="columns mt-6">
                        <div className="column"/>
                        <div className="column is-mobile is-three-quarters-mobile is-offset-2-mobile is-half is-offset-one-quarter has-text-centered">
                            Loading...
                            <progress className="progress is-small is-primary" max="100">15%</progress>
                        </div>
                        <div className="column"/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Router>
                <RedirectWrapper/>
            </Router>
        </div>
    );

}

const RedirectWrapper = () => {
    const [searchParams] = useSearchParams();

    if(searchParams.get("response_type")) {
        window.location.href = process.env.REACT_APP_SERVER_URL + '/authorization?'+searchParams.toString()
    }
    if(!searchParams.size || searchParams.size === 0) {
        return(
            <>
                <HeaderBar/>
                <Routes>
                    <Route path={"/"} Component={HomeComponent}/>
                    <Route path={"/mfa"} Component={MfaComponent}/>
                    <Route path={"/clients"} Component={ClientsComponent}/>
                    <Route path={"/clients/add"} Component={AddClient}/>
                    <Route path="/clients/:id" Component={Client}/>
                </Routes>
            </>
        )
    }

    return(<></>)

}

const connector = connect(mapState);

export default connector(App);