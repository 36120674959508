import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {
    useAddClientSecretMutation, useAddScopeMutation,
    useAuthStatusQuery,
    useClientIdsQuery,
    useDelClientSecretMutation, useDeleteScopeMutation
} from "../../services/hsoid";
import DateTimePicker from "react-datetime-picker";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import {Scope, Value} from "../../types";
import {TrashSharp} from "react-ionicons";


const Client = () => {
    const routeParams = useParams();
    const {data, error, isLoading} = useClientIdsQuery();
    const [secretName, setSecretName] = useState<string>('');
    const [expires, setExpires] = useState<Value>();
    const [addClientSecret] = useAddClientSecretMutation();
    const [newSecret, setNewSecret] = useState<string>('');
    const [delSecret] = useDelClientSecretMutation();
    const {data: authData} = useAuthStatusQuery();
    const [scopeData, setScopeData] = useState<Scope|undefined>(undefined);
    const [addScope] = useAddScopeMutation();
    const [delScope] = useDeleteScopeMutation();

    if(!data) {
        return(<p>Loading</p>)
    }

    const client = data.filter(d => d.client_id === routeParams.id)[0];

    const handleScopeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const o = authData?.scopes.find(d => d.id === event.target.value);
        setScopeData(o);
    }

    const submitNewScope = (e: React.FormEvent) => {
        e.preventDefault();
        if(scopeData) {
            addScope({
                client_id: client.client_id,
                scope: scopeData
            });
        }
    }

    const submitNewClientSecret = (e: React.FormEvent) => {
        e.preventDefault();
        addClientSecret({
            name: secretName,
            expires,
            client_id: client.client_id
        }).then((d) => {
            if('data' in d) {
                setExpires(null);
                setSecretName('');
                setNewSecret(d.data.secret);
            }
        });
    }

    return(
        <div className="container">
            <div className="content">
                <div className="columns mt-6">
                    <div className="column">
                        <div className="columns">
                            <div className="column">
                                <div className={"box"}>
                                    <h3 className="title">Client Info</h3>
                                    <p>ID: {client.client_id}</p>
                                    <p>Name: {client.client_name}</p>
                                </div>
                            </div>
                            <div className="column">
                                <div className={"box"}>
                                    <h3 className="title">Scopes</h3>
                                    <p>
                                    {client.scopes.map((scope) => {
                                        return(<span className="tag is-rounded" style={{marginRight: '4px'}}>{scope.name}
                                            <button className="delete is-small" onClick={() => {
                                                delScope({client_id: client.client_id, scope_id: scope.id})
                                            }}></button></span>)
                                    })}
                                    </p>
                                    <form onSubmit={submitNewScope}>
                                        <div className="select">
                                            <select onChange={handleScopeSelect}>
                                                <option value={""}>Add new scope</option>
                                                {authData?.scopes.map((scope) => {
                                                    if (!client.scopes.find(e => e.name === scope.name)) {
                                                        return (<option value={scope.id}>{scope.name}</option>)
                                                    }
                                                }
                                                )
                                                }
                                            </select>
                                        </div>
                                        <button className={"button is-primary"}>Add</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className={"box"}>
                            <h3 className="title">Secrets</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>ID</th>
                                        <th>Expires</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                {client.secrets.length === 0 &&
                                <tr><td colSpan={4} className="has-text-centered">No secrets configured</td></tr>
                                }
                                {client.secrets.map((sec) => {
                                    return(
                                        <tr>
                                            <td>{sec.name}</td>
                                            <td>{sec.id}</td>
                                            <td>{sec.expires ? sec.expires : "never"}</td>
                                            <td><a onClick={() => delSecret({ client_id: client.client_id, secret_id: sec.id})}><TrashSharp/></a></td>
                                        </tr>
                                    )
                                })}
                            </table>
                            <h5>Add Secret</h5>
                            {newSecret !== '' &&
                                <article className="message is-success">
                                    <div className="message-header">
                                        <p>New Secret Added</p>
                                        <button className="delete" aria-label="delete" onClick={() => setNewSecret('')}></button>
                                    </div>
                                    <div className="message-body">
                                        <p>A new secret has been added with the secret below. Note that this is the only time you will be able to view the secret key and you should save a copy of this now.</p>
                                        <p><b>Secret:</b> {newSecret}</p>
                                    </div>
                                </article>
                            }
                            <form onSubmit={submitNewClientSecret}>
                                <div className="field">
                                    <label className="label">Name</label>
                                    <div className="control">
                                        <input className="input" type="text" placeholder="Name of Client" onChange={e => setSecretName(e.target.value)} value={secretName} />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">Expiry Date (optional)</label>
                                    <div className="control">
                                        <DateTimePicker value={expires} onChange={setExpires}/>
                                    </div>
                                </div>
                                <div className="has-text-right">
                                    <button className="button is-primary" disabled={false}>Add</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Client;