import {FingerPrintSharp, GlobeSharp, ShapesSharp} from "react-ionicons";
import React from "react";
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import {useAuthStatusQuery} from "../../services/hsoid";
import {Link} from "react-router-dom";

const mapState = (state: RootState) => ({
  authentication: state
});

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux;

const HomeComponent = (props: Props) => {
    const {data, error, isLoading} = useAuthStatusQuery();
    return(
        <div className="container">
                <div className="content">

                    <div className="columns mt-6">
                        <div className="column">
                            <p>Welcome {data?.user.firstname}</p>
                        </div>
                    </div>
                </div>
                <div className="tile is-ancestor">
                    <div className="tile is-parent">
                        <article className="tile is-child box">
                            <p className="title"><Link to={"/mfa"} style={{color: '#000000'}}><FingerPrintSharp color={'#000000'} /> MFA</Link></p>
                            <p className="content">Manage Multi-Factor Authentication settings on your account.</p>
                        </article>
                    </div>
                    <div className="tile is-parent">
                        <article className="tile is-child box">
                            <p className="title"><Link to={"/clients"} style={{color: '#000000'}}><ShapesSharp color={'#000000'} /> API Clients</Link></p>
                            <p className="content">Manage OAuth2 API clients in order to authenticate to the hSo APIs.</p>
                        </article>
                    </div>
                    <div className="tile is-parent">
                        <article className="tile is-child box">
                            <p className="title"><a href={"https://portal.hso.co.uk"} style={{color: "black"}}><GlobeSharp color={'#000000'} /> Portal</a></p>
                            <div className="content">
                                <p>Return to the customer portal.</p>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
    )
}

const connector = connect(mapState);

export default connector(HomeComponent);